<template lang="pug">
.container
  .row
    .col-md-8.offset-md-2.pb-1.pb-5.text-md-center.text-start
      .fs-3.title.fw-bold.lh-sm.pb-2 {{ title }}
      .fs-6.desc.fw-normal {{ desc }}
</template>

<script>
export default {
  name: "HeadingLayout",
  props: {
    title: String,
    desc: String,
  },
};
</script>
