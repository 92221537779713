<template lang="pug">
footer.bg-dark(v-if="!isMobileApp")
  .container.py-5.text-white
    .row
      .col-md-3.mb-5.mb-md-0
        h5.text-uppercase popular features
        ul.nav.flex-column
          li.nav-item.mb-2
            router-link.text-decoration-none.text-white(to='manage-leads' @click.native="scrollTop") Manage Leads
          li.nav-item.mb-2
            router-link.text-decoration-none.text-white(to='message-template' @click.native="scrollTop") Message Template
          li.nav-item.mb-2
            router-link.text-decoration-none.text-white(to='advertisement-tool' @click.native="scrollTop") Advertisement tool
          li.nav-item.mb-2
            router-link.text-decoration-none.text-white(to='website-builder' @click.native="scrollTop") Website Builder
      .col-md-3.mb-5.mb-md-0
        h5.text-uppercase support
        ul.nav.flex-column
          li.nav-item.mb-2
            router-link.text-decoration-none.text-white(to='term' @click.native="scrollTop") Terms
          li.nav-item.mb-2
            router-link.text-decoration-none.text-white(to='privacy-policy' @click.native="scrollTop") Privacy Policy
          li.nav-item.mb-2
            router-link.text-decoration-none.text-white(to='help-center' @click.native="scrollTop") Help Center
      .col-md-3.mb-5.mb-md-0
        h5.text-uppercase company
        ul.nav.flex-column
          li.nav-item.mb-2
            router-link.text-decoration-none.text-white(to='about-us' @click.native="scrollTop") About Us
          li.nav-item.mb-2
            router-link.text-decoration-none.text-white(to='career' @click.native="scrollTop") Careers
      .col-md-3
        form
          h5 Contact Us
          p support@kloser.app
          a.text-decoration-none.text-white(href="https://www.facebook.com/klosermobile" target="_blank") Reach us on Facebook
          p.mt-3 Suite 01-01, Level 1, Vertical Corporate Tower B, Avenue 10, The Vertical, Bangsar South City, No. 8, Jln Kerinchi, 59200 Kuala Lumpur
  .div.bg-black.py-3
    .container
      .row
        .col-md-6.order-last.order-md-first
          p.mb-0.text-white © 2022 Kloser. All rights reserved.
          .me-auto
        .col-md-6.d-flex.justify-content-md-end.order-first.order-md-last
          .social-links.d-flex.align-item-center
            .round-button.bg-primary.me-2
              a(href="https://www.facebook.com/klosermobile" target="_blank")
                img.w-75(src="/icons/002-facebook.svg")
            //.round-button.bg-primary.me-2
            //  a(href="#")
            //    img.w-75(src="/icons/001-instagram.svg")
            //.round-button.bg-primary
            //  a(href="#")
            //    img.w-75(src="/icons/003-whatsapp.svg")

</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      isMobileApp: true,
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.isMobileApp = !!(
      this.$route.query && this.$route.query["is-mobile-app"]
    );
  },
};
</script>
