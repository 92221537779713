<template lang="pug">
template(v-if="!isMobileApp")
  nav.navbar.navbar-expand-lg.p-0.navbar-transition.fixed-top.bgs-white(:class="navbarBgColorClass")
    .container
      router-link.text-black(to='/home' @click.native="scrollTop")
        img(src="/icons/kloser-icon.png")
      button.navbar-toggler(type='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation')
        span.navbar-toggler-icon
      #navbarSupportedContent.collapse.navbar-collapse
        ul.navbar-nav.me-auto.py-3.py-md-0.ps-3
          li.nav-item
            a.nav-link.text-black(aria-current='page' href="/home#intro") Why Kloser
          li.nav-item.dropdown
            a#navbarDropdown.nav-link.dropdown-toggle.text-black(href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false')
              | Features
            ul.dropdown-menu.px-3.arrow(aria-labelledby='navbarDropdown')
              li
                router-link.nav-link.text-black.dropdown-item(to='manage-leads' @click.native="scrollTop") Manage Leads
              li
                router-link.nav-link.text-black.dropdown-item(to='message-template' @click.native="scrollTop") Message template
              li
                router-link.nav-link.text-black.dropdown-item(to='advertisement-tool' @click.native="scrollTop") Advertisement tool
              li
                router-link.nav-link.text-black.dropdown-item(to='website-builder' @click.native="scrollTop") Website builder
          li.nav-item
            router-link.nav-link.text-black(to='pricing' @click.native="scrollTop") Pricing
            //a.nav-link.text-black(href='pricing') Pricing
        //.d-flex.pb-4.pb-md-0.parent
        //  button.btn.text-black.me-3.fw-bold.button(type='submit' :class="buttonBgColorClass") Log in
        //    a(href="#")
        //  button.btn.bg-black.text-primary.fw-bold.button(type='submit') Try Kloser for Free
        //    a(href="#")

</template>

<script>
export default {
  name: "Navbar",
  data: function () {
    return {
      position: null,
      last_known_y: 0,
      navbar_change_min_height: 10,
      isMobileApp: true,
    };
  },
  computed: {
    navbarBgColorClass() {
      if (this.last_known_y > this.navbar_change_min_height) {
        return "bg-white shadow-sm";
      }
      return "";
    },
    buttonBgColorClass() {
      if (this.last_known_y > this.navbar_change_min_height) {
        return "bg-primary";
      } else {
        return "bg-white";
      }
    },
  },
  mounted() {
    this.isMobileApp = !!(
      this.$route.query && this.$route.query["is-mobile-app"]
    );

    this.$nextTick(() => {
      this.last_known_y = window.scrollY;
    });

    document.addEventListener("scroll", () => {
      this.last_known_y = window.scrollY;
    });
  },
  methods: {
    scroll() {
      this.position = window.scrollY;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
