<template lang="pug">
.container
  .row.align-items-center.mb-md-5.py-2.align-items-center
    .col-md-6
       img.d-block.mx-lg-auto.img-fluid(:src="image")
    .col-md-6.py-4.py-md-0
      .fs-4.fw-bold.lh-1.mb-3 {{ title }}
      .fs-6.lead.fw-normal.ws {{ desc }}
</template>

<script>
export default {
  name: "FeatureList",
  props: {
    image: String,
    title: String,
    desc: String,
  },
};
</script>
<style>
.ws {
  white-space: pre-wrap;
}
</style>
