<template lang="pug">
Navbar
.bg-image
  .padding-top
    .container.d-none.d-md-block
      nav(aria-label='breadcrumb')
      ol.breadcrumb
        li.breadcrumb-item
          router-link.text-black.text-decoration-none(to='home') Home
        li.breadcrumb-item.active(aria-current='page') Manage Lead
    HeadingLayout(:title="title", :desc="desc")
.mb-5
  template(v-for="message in messages")
    FeatureList(:title="message.title", :desc="message.desc", :image="message.image")
Footer
</template>

<script>
import Navbar from "../../components/TheNavbar";
import Footer from "../../components/TheFooter";
import HeadingLayout from "../../layout/HeadingLayout";
import FeatureList from "../../components/FeatureList";
export default {
  name: "ManageLeads",
  components: { FeatureList, HeadingLayout, Footer, Navbar },
  data() {
    return {
      title: "Manage lead",
      desc: "Kloser is a mobile CRM built for sales professionals who run their businesses from their phone. It works with WhatsApp, SMS, email, and other popular chat apps.",
      messages: [
        {
          image: "/images/features/lead/Lead_USP_Image_1.png",
          title: "Manage leads anywhere, anytime",
          desc: "You can now manage your new leads and existing clients with Kloser easily. You can add notes, define the lead status , set reminders, track the file view count and many more. For faster leads management, group your contacts and bulk message them at once.",
        },
        {
          image: "/images/features/lead/Message_USP_Image_2.png",
          title: "Reach out to your leads faster",
          desc: "All your shared material will be watermarked by default. Your contact details and branding will automatically apply on it. Personalised PDF files help your client to reach out to you easily.",
        },
        {
          image: "/images/features/lead/Message_USP_Image_3.png",
          title: "Track Views & Client Interest",
          desc: "Get alerts when your leads open your PDF files and page links, with detailed statistics of how many times they’ve viewed the content and even how long they spent looking at it.\n\nIdentify the highest potential leads, and know exactly when they’re the most interested",
        },
      ],
    };
  },
};
</script>
